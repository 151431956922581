import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import styled from "styled-components"
import IRecommended from "../../images/svgs/discountPlan28.svg";
import IStar from "../../images/svgs/StarPurple.svg";
import Img from "gatsby-image";
import BuyButton from "../commonV3/BuyButton";


const PlansBuyPage = ({intl, location, userId, promotion_code, purchase_method, icon, ecommerce}) => {
  const PRICE_ID = {
    ADULTS: process.env.GATSBY_ADULTS,
    MONTHLY: process.env.GATSBY_MONTHLY,
    ANNUAL: process.env.GATSBY_ANNUAL,
    FREEWATCH: process.env.GATSBY_FREE_WATCH
  }
  const purchase_methods_allowed = ["web","app","landing"];
  if (!purchase_methods_allowed.includes(purchase_method)) {purchase_method="web";}
  console.log ("PURCHASE_METHOD====="+purchase_method);
  return (
    <PlansBuyPageWrapper>
      <PlanBuyPageWrapper color="#ECEBFD">
        <div className="TitleAndIcon">
          <div className="TitleAndIcon">
            <div className="Title">{intl.formatMessage({id: "buyPageAnnualSubscription"})}</div><div className="Discount"><IRecommended/></div>
          </div>
        </div>
        <div className="DescriptionWrapper">
          <div className="CardPrice">
            <span className="boldlarge">0€</span>
            <span className="boldlargewatchprice">{intl.formatMessage({ id: "planWatchPrice2" })}</span>
            <span className="labelfree">RELOJ GRATIS</span>
          </div>
          <div className="SubDescription"><span className="boldsmall">{intl.formatMessage({id: "buyPageWatch"})}</span>&nbsp;{intl.formatMessage({id: "buyPageOnePayment"})}</div>
          <div className="CardPrice">
            <span className="boldlarge">{intl.formatMessage({ id: "planAnnualPrice3" })}</span>
          </div>
          <div className="SubDescription"><span className="boldsmall">{intl.formatMessage({ id: "buyPageAnnualPayment" })}</span>&nbsp;14,67€ x 12 {intl.formatMessage({ id: "buyPageMonths" })}
          </div>
        </div>
        <BuyButton id="gta_click_pay"
                   userId={userId}
                   promotion_code={promotion_code}
                   purchase_method={purchase_method}
                   price={PRICE_ID.ANNUAL}
                   size={PRICE_ID.FREEWATCH}
                   intl={intl}
                   location={location}
        >
          <ButtonText>{intl.formatMessage({ id: "buyPageWatchAnnual" })}</ButtonText>
        </BuyButton>
      </PlanBuyPageWrapper>

      <PlanBuyPageWrapper color="#FFF">
        <div className="Title">{intl.formatMessage({ id: "buyPageMonthlySubscription" })}</div>
        <div className="DescriptionWrapper">
          <div className="CardPrice">
            <span className="boldlarge">{intl.formatMessage({ id: "planWatchPrice2" })}</span>
          </div>
          <div className="SubDescription"><span
            className="boldsmall">{intl.formatMessage({ id: "buyPageWatch" })}</span>&nbsp;{intl.formatMessage({ id: "buyPageOnePayment" })}
          </div>
          <div className="CardPrice"><span
            className="boldlarge">{intl.formatMessage({ id: "planMonthlyPrice" })}</span></div>
          <div className="SubDescription"><span
            className="boldsmall">{intl.formatMessage({ id: "buyPageMonthlyPayment" })}</span></div>
        </div>
        <BuyButton id="gta_click_pay"
                   userId={userId}
                   promotion_code={promotion_code}
                   purchase_method={purchase_method}
                   type="secondary"
                   price={PRICE_ID.MONTHLY}
                   size={PRICE_ID.ADULTS}
                   intl={intl}
                   location={location}
        >
          <ButtonText>{intl.formatMessage({ id: "buyPageWatchMonthly" })}</ButtonText>
        </BuyButton>
      </PlanBuyPageWrapper>
    </PlansBuyPageWrapper>
  )
}

const PlansBuyPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 16px;
  
  .btn--wrapper {
    display: flex;
    justify-content: center;
  }
  
  .boldlarge {
    font-family: ${({theme}) => theme.font.bold};
    ${({theme}) => theme.font_size.largerr};
  }
    .boldlargewatchprice {
      font-family: ${({theme}) => theme.font.bold};
      ${({theme}) => theme.font_size.largerr};
      text-decoration: line-through;
      color: lightgrey;
      padding: 10px;
    }
    .navidad {
      vertical-align: bottom;
    }
    .labelfree {
      background-color: #8167FF;
      white-space: nowrap;
      padding:8px;
      border-radius: 25px;
      font-family: ${({theme}) => theme.font.bold};
      ${({theme}) => theme.font_size.small};
      color:${props => (props.color ? props.color : props.theme.color.neutrals.neutral10)};
      vertical-align: super;
    }
  .labelfreenadal {
    background-color: #981885;
    white-space: nowrap;
    max-width: 90px;
    padding:8px 18px 8px 18px;
    border-radius: 25px;
    display: inline-block;
    font-family: ${({theme}) => theme.font.bold};
    ${({theme}) => theme.font_size.xsmall};
    color:${props => (props.color ? props.color : props.theme.color.neutrals.neutral10)};
    vertical-align: super;
  }
  .boldsmall {
    font-family: ${({theme}) => theme.font.bold};
    ${({theme}) => theme.font_size.small};
  }
  @media (min-width: ${props => props.theme.screen.xl}) {
    flex-direction: row;
    margin: 16px 64px 32px 0;
  }
`

const PlanBuyPageWrapper = styled.div`
  display: flex;
  padding: 16px 12px;
  margin-bottom: 20px;
  //margin-left: 16px;
  box-shadow: 0 2px 2px 1px rgb(0 0 0 / 20%);
  flex-direction: column;
  background-color: ${props => (props.color ? props.color : props.theme.color.neutrals.neutral0)};;
  border-radius: 20px;
  gap: 8px;
  position: relative;
  //align-content: center;
  //align-items: center;
  @media (min-width: ${props => props.theme.screen.xl}) {
    width: 50%;
    margin-left: 0;
  }

  svg {
    min-width: 48px;
    min-height: 48px;
  }

  .DescriptionWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;

    color: ${({ theme }) => theme.color.purple};
    @media (min-width: ${props => props.theme.screen.xl}) {
      margin-left: 8px;
      //text-align: center;
    }
    .CardPrice {
      border-bottom: 1px solid ${({ theme }) => theme.color.purple};
    }
    .DescriptionPrice {
      ${({theme}) => theme.font_size.xxlarge};
      font-family: ${({theme}) => theme.font.bold};
      color: ${({ theme }) => theme.color.purple};
    }
    .month{
      ${({theme}) => theme.font_size.regular};
      font-family: ${({theme}) => theme.font.medium};;
    }
    .SubDescription {
      ${({theme}) => theme.font_size.regular};
      padding-top: 4px;
      margin-bottom: 24px;
    }
  }
  .oferta {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  .asterisco {
    ${({theme}) => theme.font_size.xlarge};
    vertical-align: sub;
  }
  
  .NextPayment {
    color: ${({ theme }) => theme.color.purple};
    ${({theme}) => theme.font_size.small}
    margin-left: 16px;
    margin-top: 18px;
  }

  button {
    //width: 300px;
  }

  .Title {
    height: 70px;
    ${({theme}) => theme.font_size.regular};
    font-family: ${({theme}) => theme.font.bold};
    color: ${({ theme }) => theme.color.purple};
  }

  .Description {
    ${({theme}) => theme.font_size.small};
    font-family: ${({theme}) => theme.font.thin};
  }

  .TitleAndIcon {
    display: flex;
    justify-content: space-between;
    .Discount {
      .svg {
        width: 78px;height: 78px;
      }
      position: absolute;
      top: 0;
      right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto; /* Se adapta al tamaño del SVG */
      height: auto; /* Se adapta al tamaño del SVG */
      padding: 0;
    }
  }

`

const Includes = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  gap: 12px;

  width: 100%;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
  ${({ theme }) => theme.font_size.small};
  font-family: ${({ theme }) => theme.font.thin};

  @media (min-width: ${props => props.theme.screen.xl}) {
    ${({ theme }) => theme.font_size.regular};
  }
`
const StyledIcon = styled(Img)`
  position: absolute;
  width: 21px;
`
const ButtonText = styled.div`
  margin: 0;
  text-align: center;
  ${({theme}) => theme.font_size.regular};
  font-family: ${({theme}) => theme.font.bold};
`
export default injectIntl(PlansBuyPage)
